// Libs
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import { HeaderContext } from 'context/header';
import Loader from 'components/loader';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '1.875rem'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  grid-template-columns: 1fr 1fr;
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '-10px'};
  padding-bottom: ${props =>
    props.paddingBottomDesk
      ? props.paddingBottomDesk
      : props.paddingBottom && '60px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};
  margin-top: ${props => props.marginTop};
  min-height: 40vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '0px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '0 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }

    @media ${device.mobile} {
      margin: ${props => props.marginMob};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isFirstMarginBottom ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};

    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.laptop} {
    border-bottom: ${props =>
      props.borderBottom ? '1px solid #C6C8CC' : 'none'};
  }

  h1 {
    margin-top: 3.75rem;
  }

  p {
    margin-top: 1.063rem;
  }

  .gsc-control-cse {
    margin-top: 2.625rem;
    margin-bottom: 4.188rem;

    @media ${device.mobile} {
      margin-top: 2.688rem;
      margin-bottom: 8.563rem;
    }
  }

  #___gcse_0 {
    width: 75%;

    @media ${device.mobile} {
      width: 100%;
    }
  }

  .input.gsc-input,
  .gsc-input-box,
  .gsc-input-box-hover,
  .gsc-input-box-focus {
    border-radius: 31.25rem;
    border-color: #292929;
    height: 55px;
    padding-left: 10px;
  }

  table.gsc-search-box td {
    border-bottom: none;
  }

  table.gsc-search-box td {
    padding: 14px;

    @media ${device.mobile} {
      padding-top: 6px;
    }
  }

  .gscb_a {
    margin-right: 10px;
  }

  .gsst_a .gscb_a {
    color: #2c6ef2;
    :hover {
      color: #0a3a99;
    }
  }

  .gsc-search-button-v2 {
    background-color: #2c6ef2;
    -webkit-transition: 0.6s background;
    transition: 0.6s background;
    padding: 4px 16px;
    border: none;
    border-radius: 100%;
    :hover {
      background-color: #c5d7fb;
      svg {
        fill: #0a3a99;
      }
    }
  }

  .gsc-search-button-v2 svg {
    margin-top: 10px;
  }

  table.gsc-search-box td.gsc-input {
    padding: 0;
  }

  .gs-web-image-box,
  .gs-promotion-image-box {
    padding: 10px 70px 10px 0px;
    display: none;
  }

  .gsc-search-button-v2 svg {
    width: 20px;
    height: 20px;
  }

  table.gsc-search-box td {
    padding-right: 0;
  }

  .gsc-control-cse .gs-spelling,
  .gsc-control-cse .gs-result .gs-title,
  .gsc-control-cse .gs-result .gs-title * {
    padding-bottom: 5px;
  }

  .gs-result .gs-image,
  .gs-result .gs-promotion-image {
    display: none;
  }

  .gs-webResult:not(.gs-no-results-result):not(.gs-error-result) .gs-snippet,
  .gs-fileFormatType {
    padding-top: 7px;
  }

  .gsc-above-wrapper-area,
  .gcsc-find-more-on-google {
    display: none;
  }

  .gsc-control-cse,
  .gsc-control-cse .gsc-table-result {
    width: auto;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }

  .gsc-results .gsc-cursor-box {
    margin-top: 1.5rem;
  }

  .gsc-results .gsc-cursor {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    border-color: rgb(69, 167, 223);
    background-color: #2c6ef2;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .gsc-cursor-page {
    text-decoration: none !important;
  }

  .gs-no-results-result .gs-snippet,
  .gs-error-result .gs-snippet {
    background-color: #ffff;
    border: none;
  }
`;

const renderPage = (isDesktop, isScriptReady) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <h1>Busca Sírio-Libanês</h1>
      <p>Insira o termo de pesquisa desejado</p>
      {!isScriptReady && <Loader />}
      <div class="gcse-search"></div>
    </Part>
  </GridContainer>
);

const SiteSearch = () => {
  const [isDesktop, setDesktop] = useState(true);
  const [scriptLoad, setScriptLoad] = useState('');
  const [isScriptReady, setIsScriptReady] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://cse.google.com/cse.js?cx=0cd462de0e02a0b75`;
    document.body.appendChild(script);
    setScriptLoad(script);

    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof scriptLoad === 'object') {
      const interval = setInterval(() => {
        if (!!document.getElementById('gsc-i-id1')) {
          setIsScriptReady(true);
          document
            .getElementById('gsc-i-id1')
            .setAttribute('placeholder', 'Pesquisar');
          clearInterval(interval);
        }
      }, 100);
    }
  }, [scriptLoad]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return <Layout>{renderPage(isDesktop, isScriptReady)}</Layout>;
};

export default SiteSearch;
